import React from 'react';
import { Link } from 'gatsby';
import imgLogo from '../assets/image/svg/SmarttieLogo.svg';
import imgError from '../assets/image/svg/404.svg';
import Helmet from '../components/Helmet';

const Contact = () => {
  return (
    <div className='min-h-lvh grid place-items-center'>
      <div className='w-full container'>
        <Link to='/'>
          <img
            src={imgLogo}
            alt='smarttie-logo'
            className='w-[120px] md:w-[200px]'
          />
        </Link>
      </div>
      <div className='container text-white '>
        <div className='flex flex-col justify-center items-center'>
          <div className='text-center'>
            <img src={imgError} alt='' className='w-100 mx-auto' />

            <div className='flex flex-col gap-1 items-center mt-4'>
              <h3 className='text-xl md:text-2xl font-bold'>
                Page is not found!
              </h3>
              <p className='md:text-lg md:w-[70%]'>
                Collaboratively administrate empowered markets via plug-and-play
                networks. Dynamically procrastinate B2C users after installed
                base.
              </p>
              <Link
                to='/'
                className='bg-primary rounded-full py-3 px-4 hover:bg-primary/85 mt-4 '
              >
                Back to home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;

export const Head = () => {
  return <Helmet />;
};
